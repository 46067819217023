import React from "react"
import styled from '@emotion/styled'
import { css } from "@emotion/react"
import Layout from "../components/layout"

const PrivacyText = () => (
  <div>
  <h3><b>Kili Privacy Policy</b></h3>
    <p>Updated: August 23, 2022</p>
   <p>
    The Kili Chrome Extension does not collect any personal data from you or from your browsing data.<br/><br/>
    This extension sends us zero data from your computer.<br/><br/>
    We don't collect browsing data, usage data, or personal data from you.
   </p>
   <br/>
   <br/>
   </div>

)

class KiliPrivacyTextPage extends React.Component {

  render() {
    return (
      <Layout>
        <PrivacyText/>
      </Layout>
      );
  }
}

export default function KiliPrivacyText() {
  return (
    <KiliPrivacyTextPage/>
  )
}